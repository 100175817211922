var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('good-table-basic'), _c('good-table-row-group'), _c('good-table-column-search'), _c('good-table-advance-search'), _c('good-table-i18n'), _c('good-table-ssr')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }