var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Advance Search Table"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeAdvance) + " ")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "custom-search"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', [_c('label', [_vm._v("Name:")]), _c('b-form-input', {
    staticClass: "d-inline-block",
    attrs: {
      "placeholder": "Search",
      "type": "text"
    },
    on: {
      "input": _vm.advanceSearch
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', [_c('label', [_vm._v("Email:")]), _c('b-form-input', {
    staticClass: "d-inline-block",
    attrs: {
      "placeholder": "Search",
      "type": "text"
    },
    on: {
      "input": _vm.advanceSearch
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', [_c('label', [_vm._v("Post:")]), _c('b-form-input', {
    staticClass: "d-inline-block",
    attrs: {
      "placeholder": "Search",
      "type": "text"
    },
    on: {
      "input": _vm.advanceSearch
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', [_c('label', [_vm._v("City:")]), _c('b-form-input', {
    staticClass: "d-inline-block",
    attrs: {
      "placeholder": "Search",
      "type": "text"
    },
    on: {
      "input": _vm.advanceSearch
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', [_c('label', [_vm._v("Date:")]), _c('b-form-input', {
    staticClass: "d-inline-block",
    attrs: {
      "placeholder": "Search",
      "type": "text"
    },
    on: {
      "input": _vm.advanceSearch
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', [_c('label', [_vm._v("Salary:")]), _c('b-form-input', {
    staticClass: "d-inline-block",
    attrs: {
      "placeholder": "Search",
      "type": "text"
    },
    on: {
      "input": _vm.advanceSearch
    }
  })], 1)], 1)], 1)], 1), _c('vue-good-table', {
    attrs: {
      "columns": _vm.columns,
      "rows": _vm.rows,
      "rtl": _vm.direction,
      "search-options": {
        enabled: true,
        externalQuery: _vm.searchTerm
      },
      "select-options": {
        enabled: true,
        selectOnCheckboxOnly: true,
        // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        // disable the select info panel on top
        selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group

      },
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      },
      "theme": "my-theme"
    },
    on: {
      "on-row-click": _vm.onRowClick
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        return [props.column.field === 'fullName' ? _c('span', {
          staticClass: "text-nowrap"
        }, [_c('b-avatar', {
          staticClass: "mx-1",
          attrs: {
            "src": props.row.avatar
          }
        }), _c('span', [_vm._v(_vm._s(props.row.fullName))])], 1) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['3', '5', '10']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }